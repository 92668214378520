import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useHandleActionItem } from "../../../hooks/useHandleActionItem.js";
import InputText from "../../../components/forms/InputText.jsx";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.onmouseenter = Swal.stopTimer;
		toast.onmouseleave = Swal.resumeTimer;
	},
});

function AfiliatesDetails() {
	const location = useLocation();
	const navigate = useNavigate();
	const locationType = location.state?.type || "";
	const data = useMemo(() => location.state?.data || [], [location.state?.data]);
	console.log("data", data);
	const [itemId, setItemId] = useState("");
	const [ticketId, setTicketId] = useState("");
	const [type, setType] = useState("");
	const [volume, setVolume] = useState("");
	const [price, setPrice] = useState("");
	const [stopLoss, setStopLoss] = useState("");
	const [takeProfit, setTakeProfit] = useState("");
	const [profit, setProfit] = useState("");
	const [percent, setPercent] = useState("");

	const { handleDelete, handleSubmit } = useHandleActionItem(data, "positions");

	useEffect(() => {
		if (locationType === "edit" && data) {
			console.log(data);
			setItemId(data.ID || "");
			setTicketId(data.Ticket || "");
			setType(data.Tipo || "");
			setVolume(data.Lotes || "");
			setPrice(data["Precio Entrada"] || "");
			setStopLoss(data["Stop Loss"] || "");
			setTakeProfit(data["Take Profit"] || "");
			setProfit(data.Profit || "");
			setPercent(data.Porcentaje || "");
		}
	}, [locationType, data]);

	const validateForm = () => {
		if (!ticketId || !type || !volume || !price || !stopLoss || !takeProfit) {
			Toast.fire({
				icon: "error",
				title: "Todos los campos son obligatorios",
				background: "#1e2836",
			});
			return false;
		}
		return true;
	};

	const handleDeleteForm = async (event, itemId) => {
		event.preventDefault();
		if (!validateForm()) return;
		await handleDelete(itemId, "positions");
	};

	const handleSubmitForm = async (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		await handleSubmit(locationType === "add" ? "add" : "update", "positions", {
			type,
			volume,
			price,
			stopLoss,
			takeProfit,
			profit,
			percent,
			ticketId,
			id: itemId,
		});
		window.history.back();
	};

	return (
		<div>
			<form onSubmit={handleSubmitForm}>
				<h1 className="text-center">{type === "add" ? "Agregar al Historial" : "Actualizar Historial"}</h1>
				<InputText label={"Ticket ID"} placeholder={"Ticket id"} value={ticketId} setValue={setTicketId} />
				<div className="mb-3">
					<label className="form-label">
						Tipo <span className="text-danger">*</span>
					</label>
					<div>
						<div className="form-check form-check-inline">
							<input
								className="form-check-input"
								type="radio"
								name="type"
								id="sell"
								value="sell"
								checked={type === "sell"}
								onChange={(e) => setType(e.target.value)}
							/>
							<label className="form-check-label" htmlFor="sell">
								Vender
							</label>
						</div>
						<div className="form-check form-check-inline">
							<input
								className="form-check-input"
								type="radio"
								name="type"
								id="buy"
								value="buy"
								checked={type === "buy"}
								onChange={(e) => setType(e.target.value)}
							/>
							<label className="form-check-label" htmlFor="buy">
								Comprar
							</label>
						</div>
					</div>
				</div>

				<InputText label={"Volumen"} placeholder={"Cantidad de acciones e.g: 1000"} value={volume} setValue={setVolume} />
				<InputText label={"Precio"} placeholder={"Precio por acción e.g: 50"} value={price} setValue={setPrice} />
				<InputText label={"Stop Loss"} placeholder={"Precio de stop loss e.g: 45"} value={stopLoss} setValue={setStopLoss} />
				<InputText label={"Take Profit"} placeholder={"Precio de take profit e.g: 55"} value={takeProfit} setValue={setTakeProfit} />
				<InputText label={"Ganancia"} placeholder={"Ganancia esperada e.g: 5000"} value={profit} setValue={setProfit} />
				<InputText label={"Porcentaje"} placeholder={"Porcentaje de ganancia e.g: 10%"} value={percent} setValue={setPercent} />

				<div className="mb-3 d-flex justify-content-around ">
					<button type="submit" className="btn btn-outline-theme btn-lg">
						{locationType === "add" ? "Agregar" : "Actualizar"}
					</button>
					<button onClick={() => navigate("/positions")} className="btn btn-outline-theme btn-lg">
						Atrás
					</button>

					<button
						onClick={(event) => {
							handleDeleteForm(event, itemId);
						}}
						className="btn btn-outline-danger btn-lg">
						Eliminar
					</button>
				</div>
			</form>
		</div>
	);
}

export default AfiliatesDetails;
