const InputDate = ({ createdAt, setCreatedAt }) => {
	return (
		<div className="mb-3">
			<label className="form-label">
				Creado En <span className="text-danger">*</span>
			</label>
			<input
				type="date"
				className="form-control form-control-lg bg-white bg-opacity-5"
				value={createdAt ? new Date(createdAt).toISOString().slice(0, 10) : ""}
				onChange={(e) => setCreatedAt(e.target.value)}
			/>
		</div>
	);
};

export default InputDate;
