export function getChartOptions() {
  var themeColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  var themeColorRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme-rgb")
    .trim();

  return {
    bar: {
      colors: [themeColor],
      chart: { sparkline: { enabled: true } },
      tooltip: {
        x: { show: false },
        y: {
          title: {
            formatter: function (seriesName) {
              return "";
            },
          },
        },
      },
    },
    line: {
      colors: [themeColor],
      chart: { sparkline: { enabled: true } },
      stroke: { curve: "straight", width: 2 },
      tooltip: {
        x: { show: false },
        y: {
          title: {
            formatter: function (seriesName) {
              return "";
            },
          },
        },
      },
    },
    pie: {
      colors: [
        "rgba(" + themeColorRgb + ", 1)",
        "rgba(" + themeColorRgb + ", .75)",
        "rgba(" + themeColorRgb + ", .5)",
      ],
      chart: { sparkline: { enabled: true } },
      stroke: { show: false },
      tooltip: {
        x: { show: false },
        y: {
          title: {
            formatter: function (seriesName) {
              return "";
            },
          },
        },
      },
    },
    donut: {
      colors: [
        "rgba(" + themeColorRgb + ", .15)",
        "rgba(" + themeColorRgb + ", .35)",
        "rgba(" + themeColorRgb + ", .55)",
        "rgba(" + themeColorRgb + ", .75)",
        "rgba(" + themeColorRgb + ", .95)",
      ],
      chart: { sparkline: { enabled: true } },
      stroke: { show: false },
      tooltip: {
        x: { show: false },
        y: {
          title: {
            formatter: function (seriesName) {
              return "";
            },
          },
        },
      },
    },
  };
}
