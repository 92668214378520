import React, { useEffect, useState } from "react";
import { Card, CardBody, CardExpandToggler } from "./../../../components/card/card.jsx";
import Chart from "react-apexcharts";
import { apiUrl } from "../../../config/config.js";
import { getChartOptions } from "../../../components/dashboard/getChartOptions.js";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.onmouseenter = Swal.stopTimer;
		toast.onmouseleave = Swal.resumeTimer;
	},
});

function CopyTrading() {
	const [traders, setTraders] = useState({ unpaid: [], paid: [] });
	const [chartOptions, setChartOptions] = useState(getChartOptions());
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [currentUnpaidPage, setCurrentUnpaidPage] = useState(1);
	const [currentPaidPage, setCurrentPaidPage] = useState(1);
	const itemsPerPage = 10;
	const statsData = [
		{
			title: "COPIERS",
			total: "420",
			info: [
				{
					icon: "fa fa-chevron-up fa-fw me-1",
					text: "33.3% more than last week",
				},
				{
					icon: "far fa-user fa-fw me-1",
					text: "45.5% new visitors",
				},
				{
					icon: "far fa-times-circle fa-fw me-1",
					text: "3.25% bounce rate",
				},
			],
			chartData: [
				{
					name: "Visitors",
					data: [69, 34, 70, 73, 41, 32, 73, 72, 89, 57, 42, 86, 78],
				},
			],
			chartType: "bar",
			chartHeight: 30,
		},
		{
			title: "A PAGAR",
			total: "235",
			info: [
				{
					icon: "fa fa-chevron-up fa-fw me-1",
					text: "20.4% more than last week",
				},
				{
					icon: "fa fa-shopping-bag fa-fw me-1",
					text: "33.5% new orders",
				},
				{
					icon: "fa fa-dollar-sign fa-fw me-1",
					text: "6.21% conversion rate",
				},
			],
			chartData: [
				{
					name: "Sales",
					data: [81, 89, 71, 75, 53, 47, 78, 33, 71, 81, 45, 43, 68],
				},
			],
			chartType: "line",
			chartHeight: 30,
		},
		{
			title: "LOTES",
			total: "44",
			info: [
				{
					icon: "fa fa-chevron-up fa-fw me-1",
					text: "59.5% more than last week",
				},
				{
					icon: "fab fa-facebook-f fa-fw me-1",
					text: "45.5% from facebook",
				},
				{
					icon: "fab fa-youtube fa-fw me-1",
					text: "15.25% from youtube",
				},
			],
			chartData: [77, 80, 70],
			chartType: "pie",
			chartHeight: 45,
		},
		{
			title: "TRADERS",
			total: "44",
			info: [
				{
					icon: "fa fa-chevron-up fa-fw me-1",
					text: "33.3% more than last week",
				},
				{
					icon: "far fa-user fa-fw me-1",
					text: "45.5% new visitors",
				},
				{
					icon: "far fa-times-circle fa-fw me-1",
					text: "3.25% bounce rate",
				},
			],
			chartData: [
				{
					name: "Visitors",
					data: [69, 34, 70, 73, 41, 32, 73, 72, 89, 57, 42, 86, 78],
				},
			],
			chartType: "bar",
			chartHeight: 30,
		},
	];

	useEffect(() => {
		fetchTraderData();
	}, []);

	const fetchTraderData = async () => {
		try {
			const response = await fetch(apiUrl + "/getTraderData", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ startDate, endDate }),
			});
			const data = await response.json();
			console.log("RESP DATA", data);
			const processedData = processTraderData(data);
			setTraders(processedData);
		} catch (error) {
			console.error("Error fetching trader data:", error);
		}
	};

	const processTraderData = (data) => {
		if (!data || (!data.paid && !data.unpaid)) {
			return { unpaid: [], paid: [] };
		}

		const processCategory = (category) => {
			return category.map((trader) => {
				const totalLots = trader.positions.reduce((sum, position) => sum + parseFloat(position.lots), 0);
				const totalProfit = trader.positions.reduce((sum, position) => sum + parseFloat(position.profit), 0);

				return {
					id: trader.copierId,
					accountId: trader.copyTo,
					copierName: trader.copierName,
					traderName: trader.traderName,
					totalLots: totalLots,
					totalProfit: totalProfit,
					earnings: totalProfit > 0 ? totalProfit * 0.3 : 0,
					positions: trader.positions.map((position) => position.positionId),
				};
			});
		};

		return {
			unpaid: processCategory(data.unpaid || []),
			paid: processCategory(data.paid || []),
		};
	};

	const handlePay = async (trader) => {
		try {
			const response = await fetch(apiUrl + "/removeCopy", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ userId: trader.id, accountId: trader.accountId }),
			});

			if (response.ok) {
				fetchTraderData(); // Refresh the data
			} else {
				console.error("Error al marcar las posiciones como pagadas");
			}
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const handleUnpaidPageChange = (page) => {
		setCurrentUnpaidPage(page);
	};

	const handlePaidPageChange = (page) => {
		setCurrentPaidPage(page);
	};

	const renderTableRows = (data, currentPage, isPaid = false) => {
		const startIndex = (currentPage - 1) * itemsPerPage;
		const selectedData = data.slice(startIndex, startIndex + itemsPerPage);

		return selectedData.map((trader) => (
			<tr key={trader.id}>
				<td>{trader.copierName}</td>
				<td>{trader.traderName}</td>
				<td>{trader.totalLots.toFixed(2)}</td>
				<td>${trader.totalProfit.toFixed(2)}</td>
				<td>${trader.earnings.toFixed(2)}</td>
				{!isPaid && (
					<td>
						<button onClick={() => handlePay(trader)} className="btn btn-outline-info btn-sm mr-3" style={{ marginRight: "0.75rem" }}>
							<i className="fas fa-money-bill-wave"></i>
						</button>
					</td>
				)}
			</tr>
		));
	};

	const totalUnpaidPages = Math.ceil(traders.unpaid.length / itemsPerPage);
	const totalPaidPages = Math.ceil(traders.paid.length / itemsPerPage);

	return (
		<div>
			<div className="row">
				{statsData.map((stat, index) => (
					<div className="col-xl-3 col-lg-6" key={index}>
						<Card className="mb-3">
							<CardBody>
								<div className="d-flex fw-bold small mb-3">
									<span className="flex-grow-1">{stat.title}</span>
									<CardExpandToggler />
								</div>
								<div className="row align-items-center mb-2">
									<div className="col-7">
										<h3 className="mb-0">{stat.total}</h3>
									</div>
									<div className="col-5">
										<div className="mt-n2">
											{stat.chartType && chartOptions[stat.chartType] && (
												<Chart
													type={stat.chartType}
													height={stat.chartHeight}
													options={chartOptions[stat.chartType]}
													series={stat.chartData}
												/>
											)}
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				))}

				<div className="col-xl-12 col-lg-12">
					<Card className="mb-3">
						<CardBody>
							<h2>Pendientes</h2>
							<div className="table-responsive">
								<table className="table table-striped table-bordered">
									<thead>
										<tr>
											<th>Copiador</th>
											<th>Trader</th>
											<th>Lotes</th>
											<th>Profit Total</th>
											<th>Ganancias (30%)</th>
											<th>Acciones</th>
										</tr>
									</thead>
									<tbody>
										{traders.unpaid.length > 0 ? (
											renderTableRows(traders.unpaid, currentUnpaidPage)
										) : (
											<tr>
												<td colSpan="5">No hay datos disponibles</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>

							<div className="pagination">
								{Array.from({ length: totalUnpaidPages }, (_, index) => (
									<button
										key={index}
										onClick={() => handleUnpaidPageChange(index + 1)}
										className={`btn btn-sm ${currentUnpaidPage === index + 1 ? "btn-primary" : "btn-secondary"}`}>
										{index + 1}
									</button>
								))}
							</div>
						</CardBody>
					</Card>

					<Card className="mb-3">
						<CardBody>
							<h2>Pagadas</h2>
							<div className="table-responsive">
								<table className="table table-striped table-bordered">
									<thead>
										<tr>
											<th>Copiador</th>
											<th>Trader</th>
											<th>Lotes</th>
											<th>Profit Total</th>
											<th>Ganancias</th>
										</tr>
									</thead>
									<tbody>
										{traders.paid.length > 0 ? (
											renderTableRows(traders.paid, currentPaidPage, true)
										) : (
											<tr>
												<td colSpan="4">No hay datos disponibles</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>

							<div className="pagination">
								{Array.from({ length: totalPaidPages }, (_, index) => (
									<button
										key={index}
										onClick={() => handlePaidPageChange(index + 1)}
										className={`btn btn-sm ${currentPaidPage === index + 1 ? "btn-primary" : "btn-secondary"}`}>
										{index + 1}
									</button>
								))}
							</div>
						</CardBody>
					</Card>
				</div>
			</div>
		</div>
	);
}

export default CopyTrading;
