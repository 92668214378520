import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useHandleActionItem } from "../../../hooks/useHandleActionItem.js";
import InputText from "../../../components/forms/InputText.jsx";
import { apiUrl } from "../../../config/config.js";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});

function CopyTradingDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const type = location.state?.type || "";
  const data = useMemo(
    () => location.state?.data || [],
    [location.state?.data]
  );
  const [itemId, setItemId] = useState("");
  const [userId, setUserId] = useState("");
  const [traderId, setTraderId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [users, setUsers] = useState([]);

  const { handleDelete, handleSubmit } = useHandleActionItem(
    data,
    "copyTrading"
  );

  useEffect(() => {
    if (type === "edit" && data) {
      setName(data.Nombre || "");
      setEmail(data.Correo || "");
      setPassword(data.password || "");
      setItemId(data.ID || "");
      setUserId(data.Usuario || "");
      setTraderId(data.Trader || "");
    }
    fetchUsers();
  }, [type, data]);

  const fetchUsers = async () => {
    try {
      const response = await fetch(apiUrl + "/getList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ table: "users" }),
      });
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const validateForm = () => {
    if (!name || !email || !password || !userId || !traderId) {
      Toast.fire({
        icon: "error",
        title: "Todos los espacios son requeridos",
        background: "#1e2836",
      });
      return false;
    }
    return true;
  };

  const handleDeleteForm = async (event, itemId) => {
    event.preventDefault();
    if (!validateForm()) return;
    await handleDelete(itemId, "copyTrading");
  };

  const handleSubmitForm = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;
    await handleSubmit(type === "add" ? "add" : "update", "copyTrading", {
      name,
      email,
      password,
      userId,
      traderId,
      id: itemId,
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmitForm}>
        <h1 className='text-center'>
          {type === "add" ? "Agregar Copy Trading" : "Actualizar Copy Trading"}
        </h1>
        <div className='mb-3'>
          <label className='form-label'>
            Usuario <span className='text-danger'>*</span>
          </label>
          <select
            className='form-select form-control-lg bg-white bg-opacity-5'
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          >
            <option value=''>Seleccionar usuario</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.id} - {user.email}
              </option>
            ))}
          </select>
        </div>
        <InputText
          label='Trader Id'
          placeholder={"Trader id"}
          value={traderId}
          setValue={setTraderId}
        />
        <InputText
          label='Name'
          placeholder={"Name"}
          value={name}
          setValue={setName}
        />
        <InputText
          label='Email'
          placeholder={"Email"}
          value={email}
          setValue={setEmail}
        />
        <InputText
          label='Password'
          placeholder={"Password"}
          value={password}
          setValue={setPassword}
        />
        <div className='mb-3 d-flex justify-content-around '>
          <button type='submit' className='btn btn-outline-theme btn-lg'>
            {type === "add" ? "Agregar" : "Actualizar"}
          </button>
          <button
            onClick={() => navigate("/copyTrading")}
            className='btn btn-outline-theme btn-lg'
          >
            Atrás
          </button>

          <button
            onClick={(event) => {
              handleDeleteForm(event, itemId);
            }}
            className='btn btn-outline-danger btn-lg'
          >
            Eliminar
          </button>
        </div>
      </form>
    </div>
  );
}

export default CopyTradingDetails;
