import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useHandleActionItem } from "../../../hooks/useHandleActionItem.js";
import InputText from "../../../components/forms/InputText.jsx";
import InputDate from "../../../components/forms/InputDate.jsx";
import { apiUrl } from "../../../config/config.js";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
});

function SymbolDetails() {
	const location = useLocation();
	const navigate = useNavigate();
	const locationType = location.state?.type || "";
	const data = useMemo(() => location.state?.data || [], [location.state?.data]);
	console.log("DATA", data);
	const [itemId, setItemId] = useState("");
	const [symbol, setSymbol] = useState("");
	const [name, setName] = useState("");
	const [contractSize, setContractSize] = useState("");
	const [tickSize, setTickSize] = useState("");
	const [marginPercent, setMarginPercent] = useState("");
	const [swapBuy, setSwapBuy] = useState("");
	const [swapSell, setSwapSell] = useState("");
	const [spread, setSpread] = useState("");

	const { handleDelete, handleSubmit } = useHandleActionItem(data, "symbols");

	useEffect(() => {
		if (locationType === "edit" && data) {
			setItemId(data.ID || "");
			setSymbol(data.Simbolo || "");
			setName(data.Nombre || "");
			setContractSize(data["Tamaño Contrato"] || "");
			setTickSize(data["Tamaño Tick"] || "");
			setMarginPercent(data["Porcentaje Margen"] || "");
			setSwapBuy(data["Swap Buy"] || "");
			setSwapSell(data["Swap Sell"] || "");
			setSpread(data.Spread || "");
		}
	}, [locationType, data]);

	const validateForm = () => {
		console.log({
			symbol,
			name,
			contractSize,
			tickSize,
			marginPercent,
			swapBuy,
			swapSell,
			spread,
			id: itemId,
		});
		if (!symbol || !name || !contractSize || !tickSize || !marginPercent || !swapBuy || !swapSell || !spread) {
			Toast.fire({
				icon: "error",
				title: "Todos los espacios son requeridos",
				background: "#1e2836",
			});
			return false;
		}
		return true;
	};

	const handleDeleteForm = async (event, itemId) => {
		event.preventDefault();
		if (!validateForm()) return;
		const data = {
			id: itemId,
		};
		await handleDelete(data, "symbols");
	};

	const handleSubmitForm = async (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		await handleSubmit(locationType === "add" ? "add" : "update", "symbols", {
			symbol,
			name,
			contractSize,
			tickSize,
			marginPercent,
			swapBuy,
			swapSell,
			spread,
			id: itemId,
		});
		navigate("/symbols");
	};

	return (
		<div>
			<form onSubmit={handleSubmitForm}>
				<h1 className="text-center">{locationType === "add" ? "Agregar símbolo" : "Actualizar símbolo"}</h1>
				<InputText label={"Simbolo"} placeholder={"AUDUSD"} value={symbol} setValue={setSymbol} />
				<InputText label={"Nombre"} placeholder={"Australian Dollar vs US Dollar"} value={name} setValue={setName} />
				<InputText label={"Tamaño Contrato"} placeholder={"100000"} value={contractSize} setValue={setContractSize} />
				<InputText label={"Tamaño Tick"} placeholder={"0.00001"} value={tickSize} setValue={setTickSize} />
				<InputText label={"Porcentaje Margen"} placeholder={"1"} value={marginPercent} setValue={setMarginPercent} />
				<InputText label={"Swap Buy"} placeholder={"-4"} value={swapBuy} setValue={setSwapBuy} />
				<InputText label={"Swap Sell"} placeholder={"-3"} value={swapSell} setValue={setSwapSell} />
				<InputText label={"Spread"} placeholder={"0.2"} value={spread} setValue={setSpread} />
				<div className="mb-3 d-flex justify-content-around ">
					<button type="submit" className="btn btn-outline-theme btn-lg">
						{locationType === "add" ? "Agregar" : "Actualizar"}
					</button>
					<button onClick={() => navigate("/symbols")} className="btn btn-outline-theme btn-lg">
						Atrás
					</button>

					<button
						onClick={(event) => {
							handleDeleteForm(event, itemId);
						}}
						className="btn btn-outline-danger btn-lg">
						Eliminar
					</button>
				</div>
			</form>
		</div>
	);
}

export default SymbolDetails;
