import { createContext, useContext, useState, useEffect } from "react";
import { login } from "../auth/api/auth";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const useAuth = () => {
	return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [authToken, setAuthToken] = useLocalStorage("authToken", null);

	useEffect(() => {
		if (authToken) {
			setLoading(false);
		} else {
			setCurrentUser(null);
			setLoading(false);
		}
	}, [authToken]);

	useEffect(() => {
		if (currentUser) {
			window.localStorage.setItem(
				"userProfile",
				JSON.stringify(currentUser, (key, value) => {
					if (key === "password") {
						return undefined;
					}
					return value;
				})
			);
			setLoading(false);
		} else {
			setLoading(false);
		}
	}, [currentUser]);

	const value = {
		currentUser,
		setCurrentUser,
		loading,
		login: async (email, password) => {
			try {
				const data = await login(email, password);
				setAuthToken(data.token);
				setCurrentUser(data.user);
				return { status: true, role: data.user.role };
			} catch (error) {
				console.error(error);
				return false;
			}
		},
		logout: () => {
			setAuthToken(null);
			setCurrentUser(null);
		},
		authToken,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
