import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AppSettings } from "../../config/app-settings";
import { register } from "../api/auth";

const RegisterPage = () => {
  const context = useContext(AppSettings);
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    idNumber: "",
  });
  const [passwordError, setPasswordError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    context.setAppContentClass("p-0");

    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
    };

    // eslint-disable-next-line
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setPasswordError(true);
      return;
    }
    try {
      const data = register(formData);
      (await data) ? navigate("/login") : setError("Credenciales inválidas");
    } catch (error) {
      setError("Error al iniciar sesión");
    }

    // Simulación de envío de datos

    setRedirect(true);
  }

  function handleInputChange(event) {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  }

  if (redirect) {
    return <Navigate to="/" />;
  }

  return (
    <div className="register">
      <div className="register-content">
        <form onSubmit={handleSubmit}>
          <h1 className="text-center">Registrarse</h1>
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="mb-3">
            <div className="mb-3">
              <label className="form-label">
                Número de Identificación (Cédula){" "}
                <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control form-control-lg bg-white bg-opacity-5"
                placeholder="ej. 102340567"
                name="idNumber"
                value={formData.idNumber}
                onChange={handleInputChange}
              />
            </div>
            <label className="form-label">
              Nombre <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              placeholder="ej. Juan Pérez"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">
              Correo electrónico
              <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              placeholder="usuario@direccion.com"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">
              Contraseña <span className="text-danger">*</span>
            </label>
            <input
              type="password"
              className="form-control form-control-lg bg-white bg-opacity-5"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">
              Confirmar contraseña <span className="text-danger">*</span>
            </label>
            <input
              type="password"
              className="form-control form-control-lg bg-white bg-opacity-5"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
            />
            {passwordError && (
              <div className="text-danger">Las contraseñas no coinciden</div>
            )}
          </div>
          <div className="mb-3">
            <button
              type="submit"
              className="btn btn-outline-theme btn-lg d-block w-100"
            >
              Registrarse
            </button>{" "}
          </div>
          <div className="text-inverse text-opacity-50 text-center">
            ¿Ya tienes una cuenta? <a href="/login">Inicia sesión</a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
