import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../components/card/card.jsx";
import { socketUrl } from "../../../config/config";
import io from "socket.io-client";
import Swal from "sweetalert2";

function OpenPositions() {
	const navigate = useNavigate();
	const [positions, setPositions] = useState([]);
	const [prices, setPrices] = useState([]);
	const token = JSON.parse(localStorage.getItem("authToken"));
	const user = JSON.parse(localStorage.getItem("userProfile"));
	const [socket, setSocket] = useState(null);

	useEffect(() => {
		let data = {
			admin: String(true),
			userId: String(user.id),
			accountId: String(16),
			token: token,
		};
		console.log("SOCKET URL", socketUrl);
		const socketTo = io(socketUrl, {
			transports: ["websocket"],
			reconnection: true,
			reconnectionAttempts: 5,
			reconnectionDelay: 2000,
			query: data,
		});
		setSocket(socketTo);

		socketTo.emit("login", data);

		setTimeout(() => {
			socketTo.emit("openPositions", data);
		}, 1000);

		socketTo.on("openPositions", (data) => {
			if (data.positions) {
				// console.log("openPositions:", data);

				const formattedData = data.positions
					.filter((position) => position.accountType !== "demo")
					.map((position) => ({
						...position,
						ticketId: String(position.ticketId),
						accountId: String(position.accountId),
						accountType: String(position.accountType),
						userId: String(position.userId),
						symbolName: String(position.symbolName),
						entryDate: position.entryDate ? String(position.entryDate) : String(position.createdAt),
						entryPrice: String(position.entryPrice),
						lots: String(position.lots),
						orderPrice: String(position.orderPrice),
						pip: String(position.pip),
						pipValue: String(position.pipValue),
						price: String(position.price),
						spread: String(position.spread),
						stopLoss: String(position.stopLoss),
						takeProfit: String(position.takeProfit),
						commision: String(position.commision),
						type: String(position.type),
						equity: String(position.equity),
						profit: String(position.profit),
					}))
					.sort((a, b) => new Date(a.entryDate) - new Date(b.entryDate)); // Ordenar por fecha

				setPositions(formattedData);
				const dataP = data.prices;
				const pricesArray = Object.entries(dataP).map(([symbol, price]) => ({ symbol, price }));
				setPrices(pricesArray);
			}
		});

		// Limpiar la conexión al socket cuando el componente se desmonte
		return () => {
			socketTo.disconnect();
		};
	}, []);

	const removePosition = (position) => {
		Swal.fire({
			title: "¿Estás seguro?",
			text: "¿Quieres cerrar esta posición?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Sí, cerrar",
			cancelButtonText: "Cancelar",
			background: "#1e2836",
			color: "#ffffff",
			iconColor: "#ffa500",
		}).then((result) => {
			if (result.isConfirmed) {
				console.log("RES", result);
				if (socket) {
					console.log("REMOVE LINE:", position);
					socket.emit("closeLine", position);
				} else {
					console.error("Socket is not initialized");
				}
			}
		});
	};

	const totalProfit = positions.reduce((sum, pos) => sum + parseFloat(pos.profit), 0).toFixed(2);

	return (
		<div>
			<div>
				<div className="d-flex align-items-center mb-3">
					<div>
						<h1 className="page-header mb-0">Posiciones Abiertas</h1>
					</div>
				</div>

				<Card>
					<div className="tab-content p-4">
						<div className="tab-pane fade show active" id="allTab">
							<table className="table">
								<thead>
									<tr>
										<th>Ticket ID</th>
										<th>Account ID</th>
										<th>Type</th>
										<th>Account Type</th>
										<th>User ID</th>
										<th>Symbol Name</th>
										<th>Entry Date</th>
										<th>Entry Price</th>
										<th>Lots</th>
										<th>Spread</th>
										<th>Stop Loss</th>
										<th>Take Profit</th>
										<th>Commision</th>
										<th>Equity</th>
										<th>Profit</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{positions.map((position) => (
										<tr key={position.ticketId}>
											<td>{position.ticketId}</td>
											<td>{position.accountId}</td>
											<td>{position.type}</td>
											<td>{position.accountType}</td>
											<td>{position.userId}</td>
											<td>{position.symbolName}</td>
											<td>{new Date(position.entryDate).toLocaleString()}</td>
											<td>{position.entryPrice}</td>
											<td>{position.lots}</td>
											<td>{position.spread}</td>
											<td>{position.stopLoss}</td>
											<td>{position.takeProfit}</td>
											<td>{position.commision}</td>
											<td>{Number(position.equity).toFixed(2)}</td>
											<td style={{ color: position.profit < 0 ? "red" : "green" }}>{position.profit}</td>
											<td className="align-middle">
												<button
													onClick={() => {
														removePosition(position);
													}}
													className="btn btn-outline-danger btn-sm">
													<i className="fa fa-trash"></i>
												</button>
											</td>
										</tr>
									))}
								</tbody>
								<tfoot>
									<tr>
										<td colSpan="8">
											<strong>Totals:</strong>
										</td>
										<td>
											<strong>{positions.reduce((sum, pos) => sum + parseFloat(pos.lots), 0).toFixed(2)}</strong>
										</td>
										<td colSpan="5"></td>
										<td style={{ color: totalProfit < 0 ? "red" : "green", width: "6rem" }}>
											<strong>$ {totalProfit}</strong>
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
						<p>Total Positions: {positions.length}</p>
					</div>
				</Card>
			</div>
			<div style={{ marginTop: 70 }}>
				<div className="d-flex align-items-center mb-3">
					<div>
						<h1 className="page-header mb-0">Precios</h1>
					</div>
				</div>

				<Card>
					<div className="tab-content p-4">
						<div className="tab-pane fade show active" id="allTab">
							<table className="table">
								<thead>
									<tr>
										<th>Simbolo</th>
										<th>Precio</th>
									</tr>
								</thead>
								<tbody>
									{prices.map((position) => (
										<tr key={position.price}>
											<td>{position.symbol}</td>
											<td>{position.price}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</Card>
			</div>
		</div>
	);
}

export default OpenPositions;
