const apiUrl = "https://api.nexgenbroker.net:5000";
// const apiUrl = "https://api.nexgenbroker.net:5003";

const socketUrl = "https://api.nexgenbroker.net:5001";
// const socketUrl = "https://api.nexgenbroker.net:5002";

module.exports = {
	apiUrl,
	socketUrl,
};
