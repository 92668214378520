import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useHandleActionItem } from "../../../hooks/useHandleActionItem.js";
import InputText from "../../../components/forms/InputText.jsx";
import InputDate from "../../../components/forms/InputDate.jsx";
import { apiUrl } from "../../../config/config.js";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
});

function AccountDetails() {
	const location = useLocation();
	const navigate = useNavigate();
	const locationType = location.state?.type || "";
	const data = useMemo(() => location.state?.data || [], [location.state?.data]);
	console.log("DATA", data);
	const [itemId, setItemId] = useState("");
	const [userId, setUserId] = useState("");
	const [type, setType] = useState("");
	const [amount, setAmount] = useState("");
	const [balance, setBalance] = useState("");
	const [status, setStatus] = useState("");
	const [createdAt, setCreatedAt] = useState("");
	const [users, setUsers] = useState([]);
	const [stopOut, setStopOut] = useState("");

	const { handleDelete, handleSubmit } = useHandleActionItem(data, "accounts");

	useEffect(() => {
		if (locationType === "edit" && data) {
			setItemId(data.ID || "");
			setUserId(data["ID Usuario"] || "");
			setType(data.Tipo);
			setAmount(data.Monto || "");
			setBalance(data.Balance || "");
			setStatus(data.Estado === "0" ? false : true || false);
			setCreatedAt(data.Creado || "");
			setStopOut(data.Cierre || "");
		}
		fetchUsers();
	}, [locationType, data]);

	const fetchUsers = async () => {
		try {
			const response = await fetch(apiUrl + "/getList", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ table: "users" }),
			});
			const data = await response.json();
			setUsers(data);
		} catch (error) {
			console.error("Error fetching users:", error);
		}
	};

	const validateForm = () => {
		console.log({
			userId,
			type,
			amount,
			balance,
			stopOut,
			status,
			createdAt,
			id: itemId,
		});
		if (!userId || !type || !amount || !balance || !stopOut || !createdAt) {
			Toast.fire({
				icon: "error",
				title: "Todos los espacios son requeridos",
				background: "#1e2836",
			});
			return false;
		}
		return true;
	};

	const handleDeleteForm = async (event, itemId) => {
		event.preventDefault();
		if (!validateForm()) return;
		const data = {
			id: itemId,
		};
		await handleDelete(data, "accounts");
	};

	const handleSubmitForm = async (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		await handleSubmit(locationType === "add" ? "add" : "update", "accounts", {
			userId,
			type,
			amount,
			balance,
			stopOut,
			status: status ? "1" : "0",
			createdAt,
			id: itemId,
		});
		navigate("/accounts");
	};

	return (
		<div>
			<form onSubmit={handleSubmitForm}>
				<h1 className="text-center">{type === "add" ? "Agregar cuenta" : "Actualizar cuenta"}</h1>
				<div className="mb-3">
					<label className="form-label">
						Usuario <span className="text-danger">*</span>
					</label>
					<select
						className="form-select form-control-lg bg-white bg-opacity-5"
						value={userId}
						onChange={(e) => {
							setUserId(e.target.value);
							setCreatedAt(new Date().toISOString().split("T")[0]);
						}}>
						<option value="">Seleccionar usuario</option>
						{users.map((user) => (
							<option key={user.id} value={user.id}>
								{user.id} - {user.email}
							</option>
						))}
					</select>
				</div>

				<div className="mb-3">
					<label className="form-label">
						Tipo <span className="text-danger">*</span>
					</label>
					<div className="form-check">
						<input
							className="form-check-input"
							type="radio"
							name="type"
							value="real"
							checked={type === "real"}
							onChange={(e) => setType(e.target.value)}
							disabled={locationType === "edit" ? true : false}
						/>
						<label className="form-check-label">Real</label>
					</div>
					<div className="form-check">
						<input
							className="form-check-input"
							type="radio"
							name="type"
							value="demo"
							checked={type === "demo"}
							onChange={(e) => setType(e.target.value)}
							disabled={locationType === "edit" ? true : false}
						/>
						<label className="form-check-label">Demo</label>
					</div>
					<div className="form-check">
						<input
							className="form-check-input"
							type="radio"
							name="type"
							value="apalancada"
							checked={type === "apalancada"}
							onChange={(e) => setType(e.target.value)}
							disabled={locationType === "edit" ? true : false}
						/>
						<label className="form-check-label">Apalancada</label>
					</div>
				</div>
				<InputText label={"Monto Inicial"} placeholder={"1000"} value={amount} setValue={setAmount} />
				<InputText label={"Balance"} placeholder={"4000"} value={balance} setValue={setBalance} />
				<InputText label={"Cierre Forzado"} placeholder={"0.00"} value={stopOut} setValue={setStopOut} />
				<div className="mb-3">
					<label className="form-label">
						Estado <span className="text-danger">*</span>
					</label>
					<div className="form-check form-switch">
						<input className="form-check-input" type="checkbox" checked={status} onChange={(e) => setStatus(e.target.checked)} />
					</div>
				</div>

				<InputDate createdAt={createdAt} setCreatedAt={setCreatedAt} />

				<div className="mb-3 d-flex justify-content-around ">
					<button type="submit" className="btn btn-outline-theme btn-lg">
						{locationType === "add" ? "Agregar" : "Actualizar"}
					</button>
					<button onClick={() => navigate("/accounts")} className="btn btn-outline-theme btn-lg">
						Atrás
					</button>

					<button
						onClick={(event) => {
							handleDeleteForm(event, itemId);
						}}
						className="btn btn-outline-danger btn-lg">
						Eliminar
					</button>
				</div>
			</form>
		</div>
	);
}

export default AccountDetails;
