import { apiUrl } from "../config/config";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.onmouseenter = Swal.stopTimer;
		toast.onmouseleave = Swal.resumeTimer;
	},
});

export const useHandleActionItem = () => {
	const handleDelete = async (data, table, fromDetails = false) => {
		try {
			const response = await fetch(apiUrl + "/actions", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					action: "delete",
					table: table,
					data,
				}),
			});
			const responseData = await response.json();
			console.log("RESPONSE", responseData);
			if (responseData.ok) {
				Toast.fire({
					icon: "success",
					title: responseData.message,
					background: "#1e2836",
					color: "#fff",
				});
				if (fromDetails) {
					window.history.back();
				}
				return true;
			} else {
				Toast.fire({
					icon: "error",
					title: responseData.message,
					color: "#fff",
					background: "#1e2836",
				});
				throw new Error(responseData.message || "Error al enviar data");
			}
		} catch (error) {
			Toast.fire({
				icon: "error",
				title: error.message,
				background: "#1e2836",
				color: "#fff",
			});
			return false;
		}
	};

	const handleSubmit = async (action, table, data) => {
		try {
			const response = await fetch(apiUrl + "/actions", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					action: action === "add" ? "create" : action === null ? "update" : action,
					table,
					data: data,
					id: data.id,
				}),
			});
			const responseData = await response.json();
			console.log("DEPOSIT RESPONSE", responseData);
			if (responseData.ok) {
				Toast.fire({
					icon: "success",
					title: responseData.message,
					background: "#1e2836",
					color: "#fff",
				});
				if (action === "add") {
					window.history.back();
				}
				return true;
			} else {
				Toast.fire({
					icon: "error",
					title: responseData.message,
					color: "#fff",
					background: "#1e2836",
				});
				throw new Error(responseData.message || "Error al enviar data");
			}
		} catch (error) {
			Toast.fire({
				icon: "error",
				title: error.message,
				background: "#1e2836",
				color: "#fff",
			});
			return false;
		}
	};

	return {
		handleDelete,
		handleSubmit,
	};
};
