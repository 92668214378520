import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { apiUrl } from "../../../config/config.js";
import InputText from "../../../components/forms/InputText.jsx";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});

function PaymentsDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const type = location.state?.type || "";
  const data = useMemo(() => location.state?.data || [], [location.state]);
  const [itemId, setItemId] = useState("");
  const [userId, setUserId] = useState("");
  const [purchaseId, setPurchaseId] = useState("");
  const [accountId, setAccountId] = useState("");
  const [status, setStatus] = useState("");
  const [users, setUsers] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    if (type === "edit" && data) {
      console.log(data);
      setItemId(data.ID || "");
      setUserId(data.Usuario || "");
      setPurchaseId(data.Compra || "");
      setAccountId(data.Cuenta || "");
      setStatus(data.Estado === "0" ? false : true || false);
    }
    fetchUsers();
    fetchPurchases();
    fetchAccounts();
  }, [type, data]);

  const fetchUsers = async () => {
    try {
      const response = await fetch(apiUrl + "/getList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ table: "users" }),
      });
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchPurchases = async () => {
    try {
      const response = await fetch(apiUrl + "/getList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ table: "purchases" }),
      });
      const data = await response.json();
      setPurchases(data);
    } catch (error) {
      console.error("Error fetching purchases:", error);
    }
  };

  const fetchAccounts = async () => {
    try {
      const response = await fetch(apiUrl + "/getList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ table: "accounts" }),
      });
      const data = await response.json();
      setAccounts(data);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  const validateForm = () => {
    if (!userId || !purchaseId || !accountId) {
      Toast.fire({
        icon: "error",
        title: "Todos los espacios son requeridos",
        background: "#1e2836",
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (event, action = null) => {
    event.preventDefault();
    if (!validateForm()) return;
    try {
      const response = await fetch(apiUrl + "/actions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action:
            type === "add" ? "create" : action === null ? "update" : action,
          table: "payments",
          data: {
            userId,
            purchaseId,
            accountId,
            status: status ? "1" : "0",
            id: itemId,
          },
        }),
      });
      const responseData = await response.json();
      if (responseData.ok) {
        Toast.fire({
          icon: "success",
          title: responseData.message,
          background: "#1e2836",
          color: "#fff",
        });
        window.history.back();
      } else {
        Toast.fire({
          icon: "error",
          title: response.message,
          color: "#fff",
          background: "#1e2836",
        });
        throw new Error(responseData.message || "Error al enviar data");
      }
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message,
        background: "#1e2836",
        color: "#fff",
      });
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h1 className='text-center'>
          {type === "add" ? "Agregar Pago" : "Actualizar Pago"}
        </h1>
        <div className='mb-3'>
          <label className='form-label'>
            Usuario <span className='text-danger'>*</span>
          </label>
          <select
            className='form-select form-control-lg bg-white bg-opacity-5'
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          >
            <option value=''>Seleccionar usuario</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.id} - {user.email}
              </option>
            ))}
          </select>
        </div>
        <div className='mb-3'>
          <label className='form-label'>
            Compra <span className='text-danger'>*</span>
          </label>
          <select
            className='form-select form-control-lg bg-white bg-opacity-5'
            value={purchaseId}
            onChange={(e) => setPurchaseId(e.target.value)}
          >
            <option value=''>Seleccionar compra</option>
            {purchases.map((purchase) => (
              <option key={purchase.id} value={purchase.id}>
                {purchase.id}
              </option>
            ))}
          </select>
        </div>
        <div className='mb-3'>
          <label className='form-label'>
            Cuenta <span className='text-danger'>*</span>
          </label>
          <select
            className='form-select form-control-lg bg-white bg-opacity-5'
            value={accountId}
            onChange={(e) => setAccountId(e.target.value)}
          >
            <option value=''>Seleccionar cuenta</option>
            {accounts.map((account) => (
              <option key={account.id} value={account.id}>
                {account.id}
              </option>
            ))}
          </select>
        </div>
        {/* <InputText
          label='Purchase ID'
          placeholder={"Purchase ID"}
          value={purchaseId}
          setValue={setPurchaseId}
        />
        <InputText
          label='Account ID'
          placeholder={"Account ID"}
          value={accountId}
          setValue={setAccountId}
        /> */}
        <div className='mb-3'>
          <label className='form-label'>
            Estado <span className='text-danger'>*</span>
          </label>
          <div className='form-check form-switch'>
            <input
              className='form-check-input'
              type='checkbox'
              checked={status}
              onChange={(e) => setStatus(e.target.checked)}
            />
          </div>
        </div>

        <div className='mb-3 d-flex justify-content-around '>
          <button type='submit' className='btn btn-outline-theme btn-lg'>
            {type === "add" ? "Agregar" : "Actualizar"}
          </button>
          <button
            onClick={() => navigate("/payments")}
            className='btn btn-outline-theme btn-lg'
          >
            Atrás
          </button>

          <button
            onClick={(event) => {
              handleSubmit(event, "delete");
            }}
            className='btn btn-outline-danger btn-lg'
          >
            Eliminar
          </button>
        </div>
      </form>
    </div>
  );
}

export default PaymentsDetails;
