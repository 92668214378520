import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../components/card/card.jsx";
import { apiUrl } from "../../../config/config";
import { useHandleActionItem } from "../../../hooks/useHandleActionItem";
import ReactPaginate from "react-paginate";
import "../../../components/table/TableBody.css"; // Importa el archivo CSS
import Swal from "sweetalert2";
import TableBody from "../../../components/table/TableBody.jsx";

function Reports() {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
	const { handleDelete } = useHandleActionItem();
	const [shouldRefetch, setShouldRefetch] = useState(false);
	const [users, setUsers] = useState();
	const [accounts, setAccounts] = useState();
	const [selectedUser, setSelectedUser] = useState();
	const [selectedAccount, setSelectedAccount] = useState();
	const [table, setTable] = useState();

	// Estados para la paginación
	const [currentPage, setCurrentPage] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(20); // Estado para la cantidad de filas por página
	const [searchTerm, setSearchTerm] = useState(""); // Estado para el término de búsqueda

	useEffect(() => {
		getUsers();
	}, []);

	const getUsers = async () => {
		setIsLoading(true);
		try {
			const response = await fetch(apiUrl + "/getList", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ table: "users" }),
			});
			if (response.ok) {
				let data = await response.json();
				console.log("USERS:", data);
				setUsers(data);
			}
		} catch (error) {
			console.error("Error al realizar la solicitud:", error);
		}
	};

	const getAccounts = async (e) => {
		e.preventDefault();
		const userId = e.target.value;
		setIsLoading(true);

		if (userId) {
			try {
				console.log("USER ID:", userId);
				const response = await fetch(apiUrl + "/getList2", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ table: "accounts", userId: userId }),
				});
				if (response.ok) {
					let data = await response.json();
					console.log("ACCOUNTS:", data);
					setAccounts(data);
				}
			} catch (error) {
				console.error("Error al realizar la solicitud:", error);
			}
		}
		setIsLoading(false);
	};

	const getPositions = async (e) => {
		e.preventDefault();
		const accountId = e.target.value;
		setIsLoading(true);
		if (accountId) {
			try {
				const response = await fetch(apiUrl + "/getList2", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ table: "positions", accountId: accountId }),
				});
				if (response.ok) {
					let data = await response.json();
					console.log("POSITIONS:", data);
					setData(data);
				}
			} catch (error) {
				console.error("Error al realizar la solicitud:", error);
			}
		}
		setIsLoading(false);
	};

	const handleItemsPerPageChange = (event) => {
		setItemsPerPage(Number(event.target.value));
		setCurrentPage(0); // Reiniciar a la primera página
	};
	const filteredData = data.filter((item) =>
		Object.entries(item).some(([key, val]) => key !== "Creado" && String(val).toLowerCase().includes(searchTerm.toLowerCase()))
	);
	const offset = currentPage * itemsPerPage;
	const currentPageData = filteredData.slice(offset, offset + itemsPerPage);
	const pageCount = Math.ceil(filteredData.length / itemsPerPage);

	const handlePageClick = (event) => {
		setCurrentPage(event.selected);
	};

	const handleAddAccounts = (send) => {
		navigate(`/${table}Details`, { state: send });
	};

	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<h1 className="page-header mb-0">Reportes</h1>
				</div>
				{/* <div className="ms-auto">
					<button onClick={() => handleAddPosition({ type: "add" })} className="btn btn-outline-theme">
						<i className="fa fa-plus-circle fa-fw me-1"></i> Agregar Posiciones
					</button>
				</div> */}
			</div>

			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div>
							<div className="table-responsive">
								<div className="input-group mb-4">
									<div className="flex-fill position-relative">
										<div className="input-group">
											<span className="input-group-text">
												<i className="fa fa-search"></i>
											</span>
											<input
												type="text"
												className="form-control ps-35px"
												placeholder="Buscar"
												value={searchTerm}
												onChange={(e) => setSearchTerm(e.target.value)}
											/>
										</div>
									</div>
								</div>

								<div className="input-group mb-4">
									<div className="flex-fill position-relative me-2">
										<div className="input-group">
											<span className="input-group-text">
												<i className="fa fa-user"></i>
											</span>
											<select
												className="form-control ps-35px"
												value={selectedUser ? selectedUser.id : ""}
												onChange={(e) => {
													setSelectedUser(e.target.value);
													getAccounts(e);
												}}>
												<option value="">Todos los usuarios</option>
												{users &&
													users.length > 0 &&
													users.map((user) => (
														<option key={user.id} value={user.id}>
															{user.id} - {user.name} - {user.email}
														</option>
													))}
											</select>
										</div>
									</div>
								</div>

								{table === "positions" && (
									<div className="input-group mb-4">
										<div className="flex-fill position-relative me-2">
											<div className="input-group">
												<span className="input-group-text">
													<i className="fa fa-user"></i>
												</span>
												<select
													className="form-control ps-35px"
													value={selectedUser ? selectedUser.id : ""}
													onChange={(e) => {
														setSelectedUser(e.target.value);
														getAccounts(e);
													}}>
													<option value="">Todos los usuarios</option>
													{users &&
														users.length > 0 &&
														users.map((user) => (
															<option key={user.id} value={user.id}>
																{user.id} - {user.name} - {user.email}
															</option>
														))}
												</select>
											</div>
										</div>
										<div className="flex-fill position-relative">
											<div className="input-group">
												<span className="input-group-text">
													<i className="fa fa-briefcase"></i>
												</span>
												<select
													className="form-control ps-35px"
													value={selectedAccount ? selectedAccount.id : ""}
													onChange={(e) => {
														setSelectedAccount(e.target.value);
														console.log("ACCOUNTTTTTT:", e.target.value);
														getPositions(e);
													}}>
													<option value="">Todas las cuentas</option>
													{accounts &&
														accounts.length > 0 &&
														accounts.map((account) => (
															<option key={account.id} value={account.id}>
																{account.id} - {account.type}
															</option>
														))}
												</select>
											</div>
										</div>
									</div>
								)}

								<div className="d-flex justify-content-between align-items-center mb-3">
									<div>
										<label htmlFor="itemsPerPage">Filas por página:</label>
										<select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
											<option value={10}>10</option>
											<option value={20}>20</option>
											<option value={50}>50</option>
											<option value={100}>100</option>
										</select>
									</div>
								</div>
								{data.length === 0 && !isLoading && (
									<div className="text-center">
										<h1>No se encuentran datos</h1>
									</div>
								)}
								{data.length === 0 && isLoading && (
									<div className="text-center">
										<div className="spinner-border text-primary" role="status">
											<span className="visually-hidden">Cargando...</span>
										</div>
									</div>
								)}
								{data.length > 0 && (
									<>
										<table className="table table-hover text-nowrap">
											<thead>
												<tr>
													{Object.keys(currentPageData[0] || {}).map((key, i) => (
														<th key={i} className="align-middle">
															{key}
														</th>
													))}
													<th className="pt-0 pb-2">Acción</th>
												</tr>
											</thead>
											{currentPageData.map((item, index) => (
												<tbody key={index}>
													<tr>
														{Object.entries(item).map(([key, value], i) => (
															<td className="align-middle" key={i}>
																{key === "Estado"
																	? typeof value === "string" && value === "1"
																		? "🟢"
																		: typeof value === "string" && value === "0"
																		? "🔴"
																		: value
																	: key === "Rol"
																	? value === "customer"
																		? "Cliente"
																		: value
																	: key === "Creado"
																	? new Date(value).toLocaleString().replace("T", " ").replace("Z", "") // Formatear la fecha
																	: key === "Contraseña"
																	? "********"
																	: value}
															</td>
														))}
														{/* <td className="align-middle">
															<button
																onClick={() => handleAddAccounts({ type: "edit", data: item })}
																className="btn btn-outline-primary btn-sm mr-3"
																style={{ marginRight: "0.75rem" }}>
																<i className="fa fa-edit"></i>
															</button>
															<button onClick={() => doDelete(item)} className="btn btn-outline-danger btn-sm">
																<i className="fa fa-trash"></i>
															</button>
														</td> */}
													</tr>
												</tbody>
											))}
										</table>

										<ReactPaginate
											previousLabel={<i className="fa fa-chevron-left"></i>}
											nextLabel={<i className="fa fa-chevron-right"></i>}
											pageCount={pageCount}
											onPageChange={handlePageClick}
											containerClassName={"pagination"}
											previousLinkClassName={"pagination__link"}
											nextLinkClassName={"pagination__link"}
											disabledClassName={"pagination__link--disabled"}
											activeClassName={"pagination__link--active"}
											pageClassName={"pagination__page"}
											breakClassName={"pagination__break"}
										/>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</Card>
		</div>
	);
}

export default Reports;
