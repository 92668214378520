import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useHandleActionItem } from "../../../hooks/useHandleActionItem.js";
import InputText from "../../../components/forms/InputText.jsx";
import { apiUrl } from "../../../config/config.js";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.onmouseenter = Swal.stopTimer;
		toast.onmouseleave = Swal.resumeTimer;
	},
});

function UserDetails() {
	const location = useLocation();
	const navigate = useNavigate();
	const locationType = location.state?.type || "";
	const data = useMemo(() => location.state?.data || [], [location.state?.data]);
	console.log("DATA", data);
	const [itemId, setItemId] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [role, setRole] = useState("");
	const [country, setCountry] = useState("");
	const [bookType, setBookType] = useState("");
	const { handleDelete, handleSubmit } = useHandleActionItem();

	useEffect(() => {
		if (locationType === "edit" && data) {
			setItemId(data.ID || "");
			setName(data.Nombre || "");
			setEmail(data.Correo || "");
			setPassword(data.Contraseña || "");
			setRole(data.Rol || "");
			setBookType(data.Tipo || "");
			setCountry(data.País || "");
		}
	}, [locationType, data]);

	const validateForm = () => {
		if (!name || !email || !role || !bookType) {
			Toast.fire({
				icon: "error",
				title: "Todos los espacios son requeridos",
				background: "#1e2836",
			});
			return false;
		}
		return true;
	};

	const handleSubmitForm = async (event) => {
		event.preventDefault();
		try {
			if (!validateForm()) return;
			if (locationType === "add") {
				const registerData = {
					name,
					email,
					password,
					country,
					type: bookType,
					role,
					id: itemId,
				};
				const response = await fetch(apiUrl + "/register", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(registerData),
				});
				const responseData = await response.json();
				console.log("REGISTER RESPONSE", responseData);
				if (responseData.ok) {
					Toast.fire({
						icon: "success",
						title: responseData.message,
						background: "#1e2836",
						color: "#fff",
					});
					window.history.back();
					return true;
				} else {
					Toast.fire({
						icon: "error",
						title: responseData.message,
						color: "#fff",
						background: "#1e2836",
					});
					throw new Error(responseData.message || "Error al enviar data");
				}
			} else {
				await handleSubmit("update", "users", {
					name,
					email,
					password,
					country,
					type: bookType,
					role,
					id: itemId,
				});
			}
			navigate("/users");
		} catch (error) {
			console.log("ERROR", error);
		}
	};

	const handleDeleteForm = async (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		await handleDelete(itemId, "users", true);
	};

	return (
		<div>
			<form onSubmit={handleSubmitForm}>
				<h1 className="text-center">{locationType === "add" ? "Agregar Usuario" : "Actualizar Usuario"}</h1>
				<InputText label="Nombre" placeholder={"Nombre"} value={name} setValue={setName} />
				<InputText label="Email" placeholder={"Email"} value={email} setValue={setEmail} disabled={locationType === "edit"} />
				<InputText label="Contraseña" placeholder={"Password"} value={password} setValue={setPassword} type="password" />
				<div className="mb-3">
					<label className="form-label">
						Rol <span className="text-danger">*</span>
					</label>
					<div className="form-check">
						<input
							className="form-check-input"
							type="radio"
							name="role"
							value="admin"
							checked={role === "admin"}
							onChange={(e) => setRole(e.target.value)}
						/>
						<label className="form-check-label">Admin</label>
					</div>
					<div className="form-check">
						<input
							className="form-check-input"
							type="radio"
							name="role"
							value="customer"
							checked={role === "customer"}
							onChange={(e) => setRole(e.target.value)}
						/>
						<label className="form-check-label">Customer</label>
					</div>
					<div className="form-check">
						<input
							className="form-check-input"
							type="radio"
							name="role"
							value="IB"
							checked={role === "IB"}
							onChange={(e) => setRole(e.target.value)}
						/>
						<label className="form-check-label">IB</label>
					</div>
				</div>
				<div className="mb-3">
					<label className="form-label">
						Tipo <span className="text-danger">*</span>
					</label>
					<div className="form-check">
						<input
							className="form-check-input"
							type="radio"
							name="bookType"
							value="A Book"
							checked={bookType === "A Book"}
							onChange={(e) => setBookType(e.target.value)}
						/>
						<label className="form-check-label">A Book</label>
					</div>
					<div className="form-check">
						<input
							className="form-check-input"
							type="radio"
							name="bookType"
							value="B Book"
							checked={bookType === "B Book"}
							onChange={(e) => setBookType(e.target.value)}
						/>
						<label className="form-check-label">B Book</label>
					</div>
				</div>
				<InputText label="País" placeholder={"Colombia"} value={country} setValue={setCountry} type="text" />
				<div className="mb-3 d-flex justify-content-around ">
					<button type="submit" className="btn btn-outline-theme btn-lg">
						{locationType === "add" ? "Agregar" : "Actualizar"}
					</button>
					<button onClick={() => navigate("/users")} className="btn btn-outline-theme btn-lg">
						Atrás
					</button>

					<button
						onClick={(event) => {
							handleDeleteForm(event);
						}}
						className="btn btn-outline-danger btn-lg">
						Eliminar
					</button>
				</div>
			</form>
		</div>
	);
}

export default UserDetails;
