import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";

import { useHandleActionItem } from "../../../hooks/useHandleActionItem.js";
import InputText from "../../../components/forms/InputText.jsx";
import { apiUrl } from "../../../config/config.js";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.onmouseenter = Swal.stopTimer;
		toast.onmouseleave = Swal.resumeTimer;
	},
});

function AffiliateDetails() {
	const location = useLocation();
	const navigate = useNavigate();
	const type = location.state?.type || "";
	const data = useMemo(() => location.state?.data || [], [location.state?.data]);
	console.log("DATA", data);
	const [itemId, setItemId] = useState("");
	const [accountId, setAccountId] = useState("");
	const [amount, setAmount] = useState("");
	const [currency, setCurrency] = useState("");
	const [status, setStatus] = useState("");
	const [accounts, setAccounts] = useState([]);
	const [users, setUsers] = useState([]);
	const [userId, setUserId] = useState("");

	const { handleDelete, handleSubmit } = useHandleActionItem(data, "deposits");

	useEffect(() => {
		if (type === "edit" && data) {
			console.log(data);
			setAccountId(data["ID Cuenta"] || "");
			setAmount(data.Monto || "");
			setCurrency(data.Moneda || "");
			setStatus(data.Estado === "0" ? false : true || false);
			setItemId(data.ID || "");
			setUserId(data["ID Usuario"] || "");
		}
		fetchAccounts();
		fetchUsers();
	}, [type, data]);

	const fetchUsers = async () => {
		try {
			const response = await fetch(apiUrl + "/getList", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ table: "users" }),
			});
			const data = await response.json();
			setUsers(data);
		} catch (error) {
			console.error("Error fetching users:", error);
		}
	};

	const fetchAccounts = async () => {
		try {
			const response = await fetch(apiUrl + "/getList", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ table: "accounts" }),
			});
			const data = await response.json();
			setAccounts(data);
		} catch (error) {
			console.error("Error fetching accounts:", error);
		}
	};

	const validateForm = () => {
		if (!accountId || !amount || !currency) {
			Toast.fire({
				icon: "error",
				title: "Todos los espacios son requeridos",
				background: "#1e2836",
			});
			return false;
		}
		return true;
	};

	const handleDeleteForm = async (event, itemId) => {
		event.preventDefault();
		if (!validateForm()) return;
		const data = {
			userId,
			id: itemId,
			accountId,
			amount,
		};
		await handleDelete(data, "deposits", true);
	};

	const handleSubmitForm = async (event) => {
		event.preventDefault();
		const transactionId = uuidv4();
		if (!validateForm()) return;
		await handleSubmit(type === "add" ? "add" : "update", "deposits", {
			accountId,
			amount,
			currency,
			transactionId,
			status: status ? "1" : "0",
			id: itemId,
			userId,
			createdAt: new Date(),
		});
		navigate("/deposits");
	};

	const handleUserChange = (e) => {
		const id = Number(e.target.value);
		setUserId(id);
		setStatus(true);
		setCurrency("USD");
	};

	// const filteredAccounts = accounts.filter((account) => account.userId === userId && account.type !== "demo");
	const filteredAccounts = accounts.filter((account) => account.userId === userId);

	return (
		<div>
			<form onSubmit={handleSubmitForm}>
				<h1 className="text-center">{type === "add" ? "Agregar depósitos" : "Actualizar depósitos"}</h1>
				<div className="mb-3">
					<label className="form-label">
						Usuario <span className="text-danger">*</span>
					</label>
					<select className="form-select form-control-lg bg-white bg-opacity-5" value={userId} onChange={handleUserChange}>
						<option value="">Seleccionar usuario</option>
						{users.map((user) => (
							<option key={user.id} value={user.id}>
								{user.id} - {user.name} - {user.email}
							</option>
						))}
					</select>
				</div>
				<div className="mb-3">
					<label className="form-label">
						Cuenta <span className="text-danger">*</span>
					</label>
					<select
						className="form-select form-control-lg bg-white bg-opacity-5"
						value={accountId}
						onChange={(e) => setAccountId(e.target.value)}>
						<option value="">Seleccionar cuenta</option>
						{filteredAccounts.map((account) => (
							<option key={account.id} value={account.id}>
								{account.id} - {account.type} - ${account.balance}
							</option>
						))}
					</select>
				</div>
				<InputText label="Monto" placeholder={"Monto"} value={amount} setValue={setAmount} />
				<div className="mb-3">
					<label className="form-label">
						Moneda <span className="text-danger">*</span>
					</label>
					<div className="form-check">
						<input
							className="form-check-input"
							type="radio"
							name="currency"
							value="USDT"
							checked={currency === "USDT"}
							onChange={(e) => setCurrency(e.target.value)}
						/>
						<label className="form-check-label">USDT</label>
					</div>
					<div className="form-check">
						<input
							className="form-check-input"
							type="radio"
							name="currency"
							value="USD"
							checked={currency === "USD"}
							onChange={(e) => setCurrency(e.target.value)}
						/>
						<label className="form-check-label">USD</label>
					</div>
				</div>
				<div className="mb-3">
					<label className="form-label">
						Estado <span className="text-danger">*</span>
					</label>
					<div className="form-check form-switch">
						<input className="form-check-input" type="checkbox" checked={status} onChange={(e) => setStatus(e.target.checked)} />
					</div>
				</div>
				<div className="mb-3 d-flex justify-content-around ">
					<button type="submit" className="btn btn-outline-theme btn-lg">
						{type === "add" ? "Agregar" : "Actualizar"}
					</button>
					<button onClick={() => navigate("/deposits")} className="btn btn-outline-theme btn-lg">
						Atrás
					</button>

					<button
						onClick={(event) => {
							handleDeleteForm(event, itemId);
						}}
						className="btn btn-outline-danger btn-lg">
						Eliminar
					</button>
				</div>
			</form>
		</div>
	);
}

export default AffiliateDetails;
