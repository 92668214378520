import React from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const ProtectedRoute = () => {
  const { authToken } = useAuth();
  const [authTokenLocal] = useLocalStorage("authToken", null);
  return authToken === authTokenLocal ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default ProtectedRoute;
