import React from "react";
import { Navigate } from "react-router-dom";
import Dashboard from "./../pages/dashboard/dashboard.js";
import OpenPositions from "../pages/pages/openPositions/openPositions.js";
import PaymentsDetails from "../pages/pages/payments/paymentsDetails.js";
import Users from "../pages/pages/users/users.js";
import UsersDetails from "../pages/pages/users/usersDetails.js";
import Accounts from "../pages/pages/accounts/accounts.js";
import AccountDetails from "../pages/pages/accounts/accountDetails.js";
import Symbols from "../pages/pages/symbols/symbols.js";
import SymbolsDetails from "../pages/pages/symbols/symbolsDetails.js";
import Products from "../pages/pages/products/products.js";
import ProductsDetails from "../pages/pages/products/productsDetails.js";
import CopyTrading from "../pages/pages/copyTrading/copyTrading.js";
import CopyTradingDetails from "../pages/pages/copyTrading/copyTradingDetails.js";
import Deposits from "../pages/pages/deposits/deposits.js";
import DepositsDetails from "../pages/pages/deposits/depositsDetails.js";
import Positions from "../pages/pages/positions/positions.js";
import PositionsDetails from "../pages/pages/positions/positionsDetails.js";
import Purchases from "../pages/pages/purchases/purchases.js";
import PurchasesDetails from "../pages/pages/purchases/purchasesDetails.js";
import Withdrawals from "../pages/pages/withdrawals/withdrawals.js";
import WithdrawalsDetails from "../pages/pages/withdrawals/withdrawalsDetails.js";
import Reports from "./../pages/pages/reports/reports.js";
import IBs from "./../pages/pages/ib/ibs.js";
import PagesError from "./../pages/pages/error.js";
import LoginPage from "../auth/pages/LoginPage.jsx";
import ProtectedRoute from "../auth/components/ProtectedRoute.js";
import RegisterPage from "../auth/pages/RegisterPage.jsx";
import App from "../app.jsx";

const AppRoute = [
	{
		path: "",
		element: <App />,
		children: [
			{ path: "login", element: <LoginPage /> },
			{ path: "register", element: <RegisterPage /> },
			{ path: "/", element: <Navigate to="/login" /> },
			{
				path: "/*",
				element: <ProtectedRoute />,
				children: [
					{
						path: "dashboard",
						element: <Dashboard />,
					},
					{
						path: "users",
						element: <Users />,
					},
					{
						path: "usersDetails",
						element: <UsersDetails />,
					},
					{
						path: "accounts",
						element: <Accounts />,
					},
					{
						path: "accountsDetails",
						element: <AccountDetails />,
					},
					{
						path: "symbols",
						element: <Symbols />,
					},
					{
						path: "symbolsDetails",
						element: <SymbolsDetails />,
					},
					{
						path: "products",
						element: <Products />,
					},
					{
						path: "productsDetails",
						element: <ProductsDetails />,
					},
					{
						path: "purchases",
						element: <Purchases />,
					},
					{
						path: "purchasesDetails",
						element: <PurchasesDetails />,
					},
					{
						path: "copyTrading",
						element: <CopyTrading />,
					},
					{
						path: "copyTradingDetails",
						element: <CopyTradingDetails />,
					},
					{
						path: "positions",
						element: <Positions />,
					},
					{
						path: "positionsDetails",
						element: <PositionsDetails />,
					},
					{
						path: "withdrawals",
						element: <Withdrawals />,
					},
					{
						path: "withdrawalsDetails",
						element: <WithdrawalsDetails />,
					},
					{
						path: "deposits",
						element: <Deposits />,
					},
					{
						path: "depositsDetails",
						element: <DepositsDetails />,
					},
					{ path: "ibs", element: <IBs /> },
					{ path: "reports", element: <Reports /> },
					{ path: "openPositions", element: <OpenPositions /> },
					{ path: "paymentsDetails", element: <PaymentsDetails /> },
					{ path: "*", element: <PagesError /> },
				],
			},
		],
	},
];

export default AppRoute;
