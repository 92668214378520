import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import InputText from "../../../components/forms/InputText.jsx";
import { useHandleActionItem } from "../../../hooks/useHandleActionItem.js";
import InputDate from "../../../components/forms/InputDate.jsx";
import { apiUrl } from "../../../config/config.js";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.onmouseenter = Swal.stopTimer;
		toast.onmouseleave = Swal.resumeTimer;
	},
});

function PurchasesDetails() {
	const location = useLocation();
	const navigate = useNavigate();
	const locationType = location.state?.type || "";
	const data = useMemo(() => location.state?.data || [], [location.state?.data]);
	console.log("DATA", data);

	const [itemId, setItemId] = useState("");
	const [userId, setUserId] = useState("");
	const [productId, setProductId] = useState("");
	const [amount, setAmount] = useState("");
	const [status, setStatus] = useState("");
	const [createdAt, setCreatedAt] = useState("");
	const [users, setUsers] = useState([]);
	const [products, setProducts] = useState([]);
	const [accountId, setAccountId] = useState(""); // Nuevo estado para accountId

	const { handleDelete, handleSubmit } = useHandleActionItem();

	useEffect(() => {
		if (locationType === "edit" && data) {
			setItemId(data.ID || "");
			setUserId(data["ID Usuario"] || "");
			setProductId(data["ID Producto"] || "");
			setAmount(data.Monto || "");
			setStatus(data.Estado === "0" ? false : true || false);
			setCreatedAt(data.Fecha || "");
			setAccountId(data["ID Cuenta"] || ""); // Manejar accountId
		}
		fetchUsers();
		fetchProducts();
	}, [locationType, data]);

	const fetchUsers = async () => {
		try {
			const response = await fetch(apiUrl + "/getList", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ table: "users" }),
			});
			const data = await response.json();
			setUsers(data);
		} catch (error) {
			console.error("Error fetching users:", error);
		}
	};

	const fetchProducts = async () => {
		try {
			const response = await fetch(apiUrl + "/getList", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ table: "products" }),
			});
			const data = await response.json();
			setProducts(data);
		} catch (error) {
			console.error("Error fetching products:", error);
		}
	};

	const validateForm = () => {
		if (!userId || !productId || !createdAt || !amount || status === undefined) {
			console.log("VALIDATE FORM", userId, productId, createdAt, amount, status);
			Toast.fire({
				icon: "error",
				title: "Todos los espacios son requeridos",
				background: "#1e2836",
			});
			return false;
		}
		return true;
	};

	const handleDeleteForm = async (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		const data = {
			id: itemId,
			accountId,
		};
		await handleDelete(data, "purchases", true);
	};

	const handleSubmitForm = async (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		await handleSubmit(locationType === "add" ? "add" : "update", "purchases", {
			productId,
			userId,
			amount,
			status: status ? "1" : "0",
			createdAt,
			id: itemId,
			accountId,
		});
		navigate("/purchases");
	};

	return (
		<div>
			<form onSubmit={handleSubmitForm}>
				<h1 className="text-center">{locationType === "add" ? "Agregar compra" : "Actualizar compra"}</h1>
				<div className="mb-3">
					<label className="form-label">
						Usuario <span className="text-danger">*</span>
					</label>
					<select className="form-select form-control-lg bg-white bg-opacity-5" value={userId} onChange={(e) => setUserId(e.target.value)}>
						<option value="">Seleccionar usuario</option>
						{users.map((user) => (
							<option key={user.id} value={user.id}>
								{user.id} - {user.email}
							</option>
						))}
					</select>
				</div>
				<div className="mb-3">
					<label className="form-label">
						Producto <span className="text-danger">*</span>
					</label>
					<select
						className="form-select form-control-lg bg-white bg-opacity-5"
						value={productId}
						onChange={(e) => {
							const selectedProduct = products.find((product) => product.id === Number(e.target.value));
							setProductId(e.target.value);
							setStatus(true);
							setAmount(selectedProduct?.amount || "");
							setCreatedAt(new Date().toISOString().split("T")[0]); // Set current date
						}}>
						<option value="">Seleccionar producto</option>
						{products.map((product) => (
							<option key={product.id} value={product.id}>
								{product.id} {product.description}
							</option>
						))}
					</select>
				</div>
				<InputText label={"Cuenta"} placeholder={"No Llenar (Dejar Vacio)"} value={accountId} />
				<InputText label={"Monto"} placeholder={"1000"} value={amount} setValue={setAmount} />
				<div className="mb-3">
					<label className="form-label">
						Estado <span className="text-danger">*</span>
					</label>
					<div className="form-check form-switch">
						<input
							className="form-check-input"
							type="checkbox"
							checked={status}
							onChange={(e) => {
								console.log("STATUS", e.target.checked);
								setStatus(e.target.checked);
							}}
						/>
					</div>
				</div>
				<InputDate createdAt={new Date()} setCreatedAt={setCreatedAt} />
				<div className="mb-3 d-flex justify-content-around ">
					<button type="submit" className="btn btn-outline-theme btn-lg">
						{locationType === "add" ? "Agregar" : "Actualizar"}
					</button>
					<button onClick={() => navigate("/purchases")} className="btn btn-outline-theme btn-lg">
						Atrás
					</button>

					<button
						onClick={(event) => {
							handleDeleteForm(event);
						}}
						className="btn btn-outline-danger btn-lg">
						Eliminar
					</button>
				</div>
			</form>
		</div>
	);
}

export default PurchasesDetails;
