import { useEffect, useState } from "react";
import { Card, CardBody, CardExpandToggler } from "../card/card";

const MovementsLog = ({ title, data, type }) => {
  console.log(data);
  const [activityLogData, setActivityLogData] = useState([]);

  useEffect(() => {
    setActivityLogData(data);
  }, [data]);

  return (
    <div className='col-xl-6'>
      <Card className='mb-3'>
        <CardBody>
          <div className='d-flex fw-bold small mb-3'>
            <span className='flex-grow-1'>{title}</span>
            <CardExpandToggler />
          </div>
          <div className='table-responsive'>
            <table className='table table-striped table-borderless mb-2px small text-nowrap'>
              <tbody>
                {activityLogData && activityLogData.length > 0 ? (
                  activityLogData.map((item) =>
                    type === "customer" ? (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.country}</td>
                      </tr>
                    ) : (
                      <tr key={item.id}>
                        <td>{item.userId}</td>
                        <td>{item.accountId}</td>
                        <td>{item.amount}</td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan='4'>No se encontraron resultados</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default MovementsLog;
