const InputText = ({
  label,
  placeholder,
  type = "text",
  value,
  setValue,
  disabled = false,
}) => {
  return (
    <div className='mb-3'>
      <label className='form-label'>
        {label + " "} <span className='text-danger'>*</span>
      </label>
      <input
        type={type}
        className='form-control form-control-lg bg-white bg-opacity-5'
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
      />
    </div>
  );
};

export default InputText;
