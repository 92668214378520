import { useNavigate } from "react-router-dom";
import { Card } from "../../../components/card/card.jsx";

import TableBody from "../../../components/table/TableBody.jsx";

function Positions() {
	const navigate = useNavigate();

	const handleAddPosition = (send) => {
		navigate("/positionsDetails", { state: send });
	};

	return (
		<div>
			<div className="d-flex align-items-center mb-3">
				<div>
					<h1 className="page-header mb-0">Posiciones</h1>
				</div>
				<div className="ms-auto">
					<button onClick={() => handleAddPosition({ type: "add" })} className="btn btn-outline-theme">
						<i className="fa fa-plus-circle fa-fw me-1"></i> Agregar Posiciones
					</button>
				</div>
			</div>

			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<TableBody table={"positions"} />
					</div>
				</div>
			</Card>
		</div>
	);
}

export default Positions;
