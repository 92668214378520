import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { useHandleActionItem } from "../../../hooks/useHandleActionItem.js";
import InputText from "../../../components/forms/InputText.jsx";
import InputDate from "../../../components/forms/InputDate.jsx";
import { apiUrl } from "../../../config/config.js";

const Toast = Swal.mixin({
	toast: true,
	position: "top-end",
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.onmouseenter = Swal.stopTimer;
		toast.onmouseleave = Swal.resumeTimer;
	},
});

function ProductDetails() {
	const location = useLocation();
	const navigate = useNavigate();
	const locationType = location.state?.type || "";
	console.log("TYPE", locationType);
	const data = useMemo(() => location.state?.data || [], [location.state?.data]);
	console.log("DATA", data);

	const [itemId, setItemId] = useState("");
	const [name, setName] = useState("");
	const [amount, setAmount] = useState("");
	const [leveragedAmount, setLeveragedAmount] = useState("");
	const [description, setDescription] = useState("");
	const [image, setImage] = useState("");

	const { handleDelete, handleSubmit } = useHandleActionItem(data, "products");

	useEffect(() => {
		if (locationType === "edit" && data) {
			console.log("DATA", data);
			setItemId(data.ID || "");
			setName(data.Nombre || "");
			setAmount(data.Monto || "");
			setLeveragedAmount(data["Monto Apalancado"] || "");
			setDescription(data.Descripcion || "");
			setImage(data.Imagen || "");
		}
	}, [locationType, data]);

	const validateForm = () => {
		if (!name || !amount || !leveragedAmount || !description || !image) {
			Toast.fire({
				icon: "error",
				title: "Todos los espacios son requeridos",
				background: "#1e2836",
			});
			return false;
		}
		return true;
	};

	const handleDeleteForm = async (event, itemId) => {
		event.preventDefault();
		if (!validateForm()) return;
		const data = {
			id: itemId,
		};
		await handleDelete(data, "products", true);
	};

	const handleSubmitForm = async (event) => {
		event.preventDefault();
		if (!validateForm()) return;
		await handleSubmit(locationType === "add" ? "add" : "update", "products", {
			name,
			amount,
			leveragedAmount,
			description,
			image,
			id: itemId,
		});
		navigate("/products");
	};

	return (
		<div>
			<form onSubmit={handleSubmitForm}>
				<h1 className="text-center">{locationType === "add" ? "Agregar producto" : "Actualizar producto"}</h1>
				<InputText label={"Nombre"} placeholder={"Nombre del producto"} value={name} setValue={setName} />
				<InputText label={"Monto"} placeholder={"Monto"} value={amount} setValue={setAmount} />
				<InputText
					label={"Monto de Apalancamiento"}
					placeholder={"Monto de Apalancamiento"}
					value={leveragedAmount}
					setValue={setLeveragedAmount}
				/>
				<InputText label={"Descripción"} placeholder={"Descripción"} value={description} setValue={setDescription} />
				<div className="mb-3">
					<label className="form-label">
						Imagen <span className="text-danger">*</span>
					</label>
					<div className="input-group">
						<input
							type="text"
							className="form-control"
							placeholder="URL de la imagen"
							value={image}
							onChange={(e) => setImage(e.target.value)}
						/>
						{image && (
							<div className="input-group-append">
								<span
									className="input-group-text p-0"
									style={{ borderTopRightRadius: "0.25rem", borderBottomRightRadius: "0.25rem", overflow: "hidden" }}>
									<img
										src={image}
										alt="Preview"
										style={{
											maxWidth: "100px",
											maxHeight: "100px",
											borderTopRightRadius: "0.50rem",
											borderBottomRightRadius: "0.50rem",
										}}
									/>
								</span>
							</div>
						)}
					</div>
				</div>

				<div className="mb-3 d-flex justify-content-around ">
					<button type="submit" className="btn btn-outline-theme btn-lg">
						{locationType === "add" ? "Agregar" : "Actualizar"}
					</button>
					<button onClick={() => navigate("/products")} className="btn btn-outline-theme btn-lg">
						Atrás
					</button>

					<button
						onClick={(event) => {
							handleDeleteForm(event, itemId);
						}}
						className="btn btn-outline-danger btn-lg">
						Eliminar
					</button>
				</div>
			</form>
		</div>
	);
}

export default ProductDetails;
